import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import * as types from "actions/types";

/*
    {
        game: {
            gameId,
            nickname:
            players:
        }
    }
*/

const game = (state = {}, action) => {
  let loading;
  let roomId, clientId, players, startFlag, gameStatus;
  let playerStatuses, space;

  switch (action.type) {
    case types.SPACE_UPDATE:
      // console.log("Space reducer received", action);
      space = action.space;
      return { ...state, space };
    case types.ENTERED_ROOM:
      clientId = action.clientId;
      loading = action.loading;
      roomId = action.roomId;
      return { ...state, clientId, loading, roomId };
    case types.UPDATE_ROOM:
      // console.log("Update room", action);
      loading = action.loading;
      roomId = action.roomId;
      players = action.players;
      playerStatuses = action.playerStatuses;
      startFlag = action.startFlag;
      return { ...state, loading, roomId, players, playerStatuses, startFlag };
    case types.START_GAME:
      gameStatus = true;
      return { ...state, gameStatus };
    case types.END_GAME:
      console.log("types.END_GAME reducer");
      gameStatus = false;
      let message = action.message;
      space = undefined;
      console.log("END GAME, action.message: ", message);
      return { ...state, gameStatus, space, message };
    case types.CLEAR_MESSAGE:
      console.log("typers.CLEAR_MESSAGE");
      let newState = { ...state };
      delete newState["message"];
      return newState;
    case types.SOCKET_CLOSED:
      return {};
    default:
      return state;
  }
};

const page = (state = {}, action) => {
  let clientError;

  switch (action.type) {
    case types.CLIENT_ERROR:
      console.log("Client error!");
      clientError = action.message;
      return { ...state, clientError };
    case types.SOCKET_CLOSED:
      return {};
    default:
      return state;
  }
};

const createRootReducer = (history) =>
  combineReducers({
    game,
    page,
    router: connectRouter(history),
  });

export default createRootReducer;
