import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";

import createRootReducer from "reducers";

const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const history = createBrowserHistory();

  const middleware = [sagaMiddleware, routerMiddleware(history)];

  return {
    ...createStore(createRootReducer(history), applyMiddleware(...middleware)),
    runSaga: sagaMiddleware.run,
    history,
  };
};

export default configureStore;
