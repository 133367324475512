import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, withRouter } from "react-router-dom";

import RouteWrapper from "helpers/RouteWrapper";
import Nav from "components/Nav";
import GameRoom from "components/GameRoom";
import GameRoomForm from "components/GameRoomForm";
import {
  createRoom,
  enterRoom,
  playerReadyAction,
  startGameAction,
  sendUserAction,
  clearMessage,
} from "actions";

import styles from "./styles/App.module.scss";

const MAX_NUM_PLAYERS = 2;

class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let _this = this;
    if (_this.props.gameStatus) {
      document.addEventListener("keyup", function (event) {
        if (event.defaultPrevented) {
          return;
        }
        let key = event.key || event.keyCode;
        let letterRegex = RegExp(/[a-z]/i);

        if (key.length == 1 && letterRegex.test(key)) {
          // console.log("Letter!", key);
          _this.props.sendUserAction({ key });
        }
        event.preventDefault();

        // switch (event.key) {
        //     case "Down": // IE specific value
        //     case "ArrowDown":
        //         // Do something for "down arrow" key press.
        //     case "Up": // IE specific value
        //     case "ArrowUp":
        //         // Do something for "up arrow" key press.
        //     case "Left": // IE specific value
        //     case "ArrowLeft":
        //         // Do something for "left arrow" key press.
        //     case "Right": // IE specific value
        //     case "ArrowRight":
        //         // Do something for "right arrow" key press.
        //     case "Enter":
        //         // Do something for "enter" or "return" key press.
        //     case "Escape":
        //         // Do something for "esc" key press.
        //         break;

        //     default:
        //         return; // Quit when this doesn't handle the key event.
        // }
      });
    }

    return (
      <div>
        <Nav />
        <div className={styles.Main}>
          <Switch>
            <RouteWrapper
              exact
              path="/"
              component={GameRoomForm}
              createRoom={this.props.createRoom}
              enterRoom={this.props.enterRoom}
              clientError={this.props.clientError}
            />
            <RouteWrapper
              path="/gameroom"
              component={GameRoom}
              loading={this.props.loading}
              roomId={this.props.roomId}
              clientId={this.props.clientId}
              clearMessage={this.props.clearMessage}
              players={this.props.players}
              playerReady={this.props.playerReady}
              playerStatuses={this.props.playerStatuses}
              startFlag={this.props.startFlag}
              startGame={this.props.startGame}
              gameStatus={this.props.gameStatus}
              message={this.props.message}
              space={this.props.space}
              roomSize={MAX_NUM_PLAYERS}
            />
          </Switch>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  // console.log("ownProps", ownProps);
  // console.log("State change", state);
  return {
    loading: state.game.loading,
    roomId: state.game.roomId,
    clientId: state.game.clientId,
    players: state.game.players,
    playerStatuses: state.game.playerStatuses,
    startFlag: state.game.startFlag,
    gameStatus: state.game.gameStatus,
    roomNumber: state.game.roomNumber,
    message: state.game.message,
    space: state.game.space,

    clientError: state.page.clientError,

    location: state.router.location,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearMessage: () => {
      dispatch(clearMessage());
    },
    createRoom: (state) => {
      dispatch(createRoom(state.username));
    },
    enterRoom: (state) => {
      dispatch(enterRoom(state.username, state.roomId));
    },
    playerReady: (readyFlag) => {
      dispatch(playerReadyAction(readyFlag));
    },
    startGame: () => {
      dispatch(startGameAction());
    },
    sendUserAction: (action) => {
      dispatch(sendUserAction(action));
    },
  };
};

const AppContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(App)
);

// const AppContainer =connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(App)

export default AppContainer;
