import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";

import App from "./App";
import configureStore from "./store";
import sagas from "./sagas";

import normalizeStyles from "styles/normalize.scss";
import globalStyles from "styles/global.scss";

let store = configureStore();
store.runSaga(sagas);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConnectedRouter history={store.history}>
        <App />
      </ConnectedRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
